<template>
	<div :class="'lending-page-funding-direct' + (isActive ? ' active' : '')">
		<div class="header">
			<div class="title">
				Lender Loan Proposals
			</div>

			<div class="nb-vspacer mini" />

			<div class="desc">
				<span>
					Perhaps you've made a verbal agreement with a friend or a relative for a loan and just need 
					a way to formalize it. Or perhaps you'd like to propose a loan to someone. It could be that 
					you'd like to ask someone for a loan, and would like to provide some assurances that the loan 
					will be paid back within a specified time period and an agreed upon interest rate.
				</span>

				<div class="nb-vspacer mini" />

				<span>
					<b>Neobanx can help!</b>
				</span>
			</div>
		</div>

		<div class="nb-vspacer xxlarge" />

		<div class="summary-container">
			<span class="summary-title">
				What We Can Do
			</span>

			<div class="nb-vspacer" />

			<span class="summary-desc">
				We offer:

				<ul>
					<li>
						Full legal Promissory Note
					</li>

					<li>
						Payments automatically collected by Pre-Authorized Debit
					</li>

					<li>
						Loan Protection that provides peace of mind (if the borrower is in a regular job — various conditions apply)
					</li>
				</ul>

				We also offer a host of flexible and constructive tools that allow people who borrow and lend 
				to deal with issues that might arise, such as skipped payments, the possible need to reschedule 
				payments, or even the opportunity to make extra payments. Should a friend or relative lose their 
				job, become sick or disabled, our Loan Protection program can help guard against these risks. 
				See the Loan Protection page for full details.

				<div class="nb-vspacer mini" />

				Whether a Lender or Borrower, Neobanx can provide a detailed proposal that you can send by email 
				or any method you chose, including details of how the loan will be paid back and at what interest 
				rates. We can also provide Loan Protection to help protect against the risks of unemployment, 
				sickness, or disability (see here for more details).
			</span>
		</div>
		
		<div class="hr" />

		<div class="summary-container">
			<span class="summary-title">
				How It Works for Borrowers
			</span>

			<div class="nb-vspacer" />

			<span class="summary-desc">
				<ul>
					<li>
						Create a Loan Proposal for Lenders, setting out how much money you are seeking, for how long, 
						at what interest rate and how much interest you are willing to pay.
					</li>

					<li>
						Add the Loan Protection feature if you want.
					</li>

					<li>
						Add your personal message.
					</li>

					<li>
						Neobanx will add a description of how the whole process works.
					</li>

					<li>
						Tell us who you want to send the proposal to. It doesn't matter if they're already signed up with Neobanx or not — registration is free.
					</li>
				</ul>

				Once the form is sent, the proposed lender can then either accept your proposal and upload money to fund the loan, or they can respond with a 
				counter-proposal. You can either accept it or make your own counter response until you both agree on the terms and the loan is then finalized.
			</span>
		</div>
		
		<div class="hr" />

		<div class="summary-container">
			<span class="summary-title">
				How It Works for Lenders
			</span>

			<div class="nb-vspacer" />

			<span class="summary-desc">
				<ul>
					<li>
						Create a Loan Proposal for Borrowers, setting out how much money you are willing to loan, 
						for how long, at what interest rate and how much interest you are seeking.
					</li>

					<li>
						Add the Loan Protection feature if you want.
					</li>

					<li>
						Add your personal message.
					</li>

					<li>
						Neobanx will add a description of how the whole process works.
					</li>

					<li>
						Tell us who you want to send the proposal to. It doesn't matter if they're already signed up with Neobanx or not — registration is free.
					</li>
				</ul>

				Once the form is sent, the proposed borrower can then either accept your proposal and have the funds uploaded to their account, or they can 
				respond with a counter-proposal. You can either accept it or make your own counter response until you both agree on the terms and the loan is then finalized.
			</span>
		</div>

		<div class="nb-vspacer xlarge" />

		<a class="button" @click="$emit('goto', 'StartNow')">
			Start Lending Money Now
		</a>

		<div class="nb-vspacer xxxlarge" />
	</div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
	name: 'LendingPage_FundingDirect',

	props: {
		isActive: false
	},

	setup() {
		// TODO
	}
})
</script>

<style lang="scss" scoped>
.lending-page-funding-direct {
	display: flex;
	flex-direction: column;
	position: absolute;
	opacity: 0;
	transition: opacity 1s;
	height: 0;
	overflow: hidden;

	.header {
		display: flex;
		flex-direction: column;
		padding: 24px 32px;
		background-color: #5761A7;
		border-radius: 8px;

		.title {
			font-size: 20px;
			font-family: Quicksand;
			font-weight: bold;
			color: #FF9800;
		}

		.desc {
			font-size: 15px;
			color: rgba(#fff, 0.6);
		}
	}

	.summary-container {
		display: flex;
		flex-direction: column;

		.summary-title {
			display: flex;
			font-size: 20px;
			line-height: 24px;
			font-family: MartelRegular;
			font-weight: bold;
			color: #5761A7;
		}

		.summary-desc {
			font-size: 17px;
			line-height: 22px;
			color: rgba(#000, 0.7);


			ul {
				list-style: none;

				li {
					padding-left: 12px;
					padding-bottom: 4px;
				}

				li::marker {
					content: "\25FC";
					color: #EA7914;
				}
			}
		}
	}

	.button {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 36px;
		padding: 0 16px;
		width: fit-content;
		border-radius: 4px;
		font-weight: bolder;
		font-size: 14px;
		color: rgba(#fff, 0.8);
		text-transform: uppercase;
		cursor: pointer;
		background: linear-gradient(135deg, #EA7916 0, #D16C13 100%);
	}

	.button:hover {
		filter: brightness(110%);
	}

	.hr {
		display: flex;
		width: 30%;
		margin-top: 28px;
		border-top: 1px dotted #FF9800;
		padding-bottom: 36px;
	}
}

.lending-page-funding-direct.active {
	opacity: 1;
	height: unset;
	overflow: unset;
}
</style>
