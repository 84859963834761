








































































import { defineComponent, reactive, watch } from '@vue/composition-api'
import LendingPage_Overview from '@/views/LendingPageSections/LendingPage_Overview.vue'
import LendingPage_MeetingPlace from '@/views/LendingPageSections/LendingPage_MeetingPlace.vue'
import LendingPage_Community from '@/views/LendingPageSections/LendingPage_Community.vue'
import LendingPage_FundingDirect from '@/views/LendingPageSections/LendingPage_FundingDirect.vue'
import LendingPage_StartNow from '@/views/LendingPageSections/LendingPage_StartNow.vue'

export default defineComponent({
	name: 'LendingPage',

	components: {
		LendingPage_Overview,
		LendingPage_MeetingPlace,
		LendingPage_Community,
		LendingPage_FundingDirect,
		LendingPage_StartNow
	},

	props: {},

	setup(props, { root }) {
		const state = reactive({
			activeSection: 'Overview'
		});

		watch(
			() => state.activeSection,
			() => {
				document.getElementById('lending-content')!.scrollTop = 0
			}
		)

		if (root.$route.query.section == 'start-now') {
			state.activeSection = 'StartNow'
			root.$router.replace('/lending')
		}

		return { state }
	}
})
