<template>
	<div :class="'lending-page-overview' + (isActive ? ' active' : '')">
		<div class="summary-container">
			<span class="summary-title">
				Lend in the Meeting Place
			</span>

			<div class="nb-vspacer" />

			<span class="summary-desc">
				Connect with people  across the country. 
				View the requests for loans that others have created 
				or create your own custom loan listing offering your money on your terms.
			</span>

			<div class="nb-vspacer" />

			<div class="action">
				<a class="flat-button" @click="$emit('goto', 'MeetingPlace')">
					Learn more

					<q-icon class="icon" name="arrow_forward" />
				</a>

				<a class="button" @click="$router.push('/login')">
					Lend in the Meeting Place

					<q-icon class="icon" name="double_arrow" />
				</a>
			</div>
		</div>
		
		<div class="hr" />

		<div class="summary-container">
			<span class="summary-title">
				Lend in a Community
			</span>

			<div class="nb-vspacer" />

			<span class="summary-desc">
				Help people that you share a bond or connection with in the Lending Communities.
				<div class="nb-vspacer mini" />
				Don't see your community online yet? Create your own and invite those you share a connection with to join.
			</span>

			<div class="nb-vspacer" />

			<div class="action">
				<a class="flat-button" @click="$emit('goto', 'Community')">
					Learn more

					<q-icon class="icon" name="arrow_forward" />
				</a>

				<a class="button" @click="$router.push('/community')">
					Lend in a Community

					<q-icon class="icon" name="double_arrow" />
				</a>
			</div>
		</div>

		<div class="hr" />

		<div class="summary-container">
			<span class="summary-title">
				Lend to a Friend
			</span>

			<div class="nb-vspacer" />

			<span class="summary-desc">
				Want to offer someone you know a loan or formalize an agreement you've already made ? We can help.
				<div class="nb-vspacer mini" />
				Send a <b>Loan Proposal</b> to your other party, once you both agree the terms it becomes a formal loan.
			</span>

			<div class="nb-vspacer" />

			<div class="action">
				<a class="flat-button" @click="$emit('goto', 'FundingDirect')">
					Learn more

					<q-icon class="icon" name="arrow_forward" />
				</a>

				<a class="button" @click="$router.push('/login')">
					Lend to a Friend

					<q-icon class="icon" name="double_arrow" />
				</a>
			</div>
		</div>

		<div class="nb-vspacer xxxlarge" />
	</div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
	name: 'LendingPage_Overview',

	props: {
		isActive: false
	},

	setup() {
		// TODO
	}
})
</script>

<style lang="scss" scoped>
.lending-page-overview {
	display: flex;
	flex-direction: column;
	position: absolute;
	opacity: 0;
	transition: opacity 1s;
	height: 0;
	overflow: hidden;

	.summary-container {
		display: flex;
		flex-direction: column;

		.summary-title {
			display: flex;
			//background: linear-gradient(90deg, #EA7914 0, #EA7914 10px, transparent 10px, transparent 100%);
			//padding-left: 15px;
			font-size: 20px;
			line-height: 24px;
			font-family: MartelRegular;
			font-weight: bold;
			color: #5761A7;
		}

		.summary-desc {
			font-size: 17px;
			line-height: 22px;
			color: rgba(#000, 0.7);
		}

		.action {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.flat-button {
				display: flex;
				align-items: center;
				color: #5761A7;
				text-transform: uppercase;
				font-weight: bolder;
				font-size: 13px;
				cursor: pointer;

				.icon {
					color: #EA7914;
					font-weight: bold;
					font-size: 16px;
					margin-left: 4px;
					cursor: pointer;
				}
			}

			.button {
				display: flex;
				justify-content: center;
				align-items: center;
				width: fit-content;
				//height: 36px;
				color: rgba(#fff, 0.9);
				border-radius: 4px;
				font-weight: bolder;
				font-size: 13px;
				text-transform: uppercase;
				padding: 2px 8px;
				background: linear-gradient(135deg, #FF9800 0, #EA7916 100%);
				//background-color: #FF9800;
				cursor: pointer;

				.icon {
					color: rgba(#fff, 0.5);
					margin-left: 12px;
					font-size: 16px;
					cursor: pointer;
				}
			}
		}
	}

	.hr {
		display: flex;
		width: 30%;
		margin-top: 28px;
		border-top: 1px dotted #FF9800;
		padding-bottom: 36px;
	}
}

.lending-page-overview.active {
	opacity: 1;
	height: unset;
	overflow: unset;
}
</style>